import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'message']

  connect() {
    this.hasChanges = false
    this.formTarget.addEventListener('input', this.detectChanges.bind(this))
  }

  detectChanges() {
    if (!this.hasChanges) {
      this.hasChanges = true
      this.showUnsavedChangesMessage()
    }
  }

  showUnsavedChangesMessage() {
    this.messageTarget.classList.remove('hidden')
  }
}
